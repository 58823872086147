import { Component } from '@angular/core';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-embedded-form',
    template: `
        <ng-template appElementHost></ng-template>
    `,
    standalone: false,
})
export class EmbeddedFormComponent extends NestedValuesBaseComponent {
    public static register(): ComponentMap {
        return {
            big_webform_embedded_form: EmbeddedFormComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
