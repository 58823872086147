import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { IMaskDirective } from 'angular-imask';
import IMask from 'imask/holder';
import 'imask/masked/number';
import { CurrencyType } from '../../models';

export const decimalMaskConfig = {
    mask: [
        { mask: '' },
        {
            mask: 'num €',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: '.',
                    normalizeZeros: false,
                    radix: ',',
                    expose: true,
                },
            },
        },
    ],
};

export const integerMaskConfig = {
    mask: [
        { mask: '' },
        {
            mask: 'num €',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 0,
                    thousandsSeparator: '.',
                    expose: true,
                },
            },
        },
    ],
};

@Component({
    selector: 'big-ui-currency-input',
    templateUrl: './currency-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, IMaskDirective, FormsModule, ReactiveFormsModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CurrencyInputComponent,
            multi: true,
        },
    ],
})
export class CurrencyInputComponent extends BaseControlValueAccessor<number> {
    @HostBinding('class') public classList = 'block';

    @Input() public id?: string;
    @Input() public isTouched = false;
    @Input() public isRequired = false;
    @Input() public isValid = true;
    @Input() public showValidation = false;

    @Output() public readonly blurred = new EventEmitter<void>();

    public currencyMask = IMask.createMask(decimalMaskConfig);
    public placeholder = '0,00 €';

    @Input() public set currencyType(currencyType: CurrencyType) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        currencyType === 'integer' ? this.setDefaultConfigInteger() : this.setDefaultConfigDecimal();
    }

    public onBlur(): void {
        this.blurred.emit();
    }

    public override onChange(value: number | string | null | undefined): void {
        super.onChange(typeof value === 'string' ? undefined : (value ?? undefined));
    }

    private setDefaultConfigDecimal(): void {
        this.currencyMask.updateOptions(decimalMaskConfig);
        this.placeholder = '0,00 €';
    }

    private setDefaultConfigInteger(): void {
        this.currencyMask.updateOptions(integerMaskConfig);
        this.placeholder = '0 €';
    }
}
