import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, SecurityContext, inject, type OnInit } from '@angular/core';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { BreakpointService, SanitizePipe } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgPopoverRef } from 'ng-overlay-container';
import { type EmbeddedOverlayData } from '../../models/embedded-overlay-data.model';

@Component({
    selector: 'big-ui-modal-embedded',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './modal-embedded.component.html',
    imports: [A11yModule, IconComponent, SanitizePipe, TranslocoDirective, UiButtonComponent],
    providers: [provideTranslationScope('uiModalEmbedded', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class ModalEmbeddedComponent implements OnInit {
    private readonly allowedImgFileExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tif', 'tiff'];
    private readonly breakpointService = inject(BreakpointService);

    public readonly iconClose = IconBigMediumSchliessen;
    public readonly popoverRef = inject(NgPopoverRef<EmbeddedOverlayData>);
    public readonly securityContext: SecurityContext = SecurityContext.RESOURCE_URL;
    public readonly viewPortSize = this.breakpointService.viewPortSize;

    public url?: string;
    public label?: string;
    public isImage = false;

    public ngOnInit(): void {
        const fileExtension = this.popoverRef.data.label.split('.').pop()?.toLowerCase();

        if (fileExtension && this.allowedImgFileExtensions.includes(fileExtension)) {
            this.isImage = true;
        }

        this.url = this.popoverRef.data.src;
        this.label = this.popoverRef.data.label;
    }
}
