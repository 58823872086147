import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
    selector: 'big-form-structure-base',
    template: '<ng-template appElementHost></ng-template>',
    standalone: false,
})
export class StructureBaseComponent extends BaseComponent {
}
