/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any */
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UploadFile, type FormConfigElement } from '@big-direkt/form/contracts';
import { type AuthorizationHeader } from '@big-direkt/state/user';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { throwError, type Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { type RequestOptions } from '../../interfaces/request-options';
import { type WebformResponse } from '../../interfaces/webform-response';
import { type WebformSubmissionResponse } from '../../interfaces/webform-submission-response';
import { type ApiConnectorBase } from '../api-connector-base.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService implements ApiConnectorBase {
    private static readonly headerCsrf: string = 'X-CSRF-Token';

    private readonly environment = inject(EnvironmentService);
    private readonly httpClient = inject(HttpClient);
    private readonly hundred = 100;

    public submittedSuccessfully = false;

    public drupalWebformConfig(formId: string, options: RequestOptions = {}): Observable<WebformResponse> {
        this.submittedSuccessfully = false;
        // eslint-disable-next-line @typescript-eslint/no-misused-spread
        options.params = { ...options.params, cb: Date.now().toString() };

        return this.get<WebformResponse>(`/api/webform/config/${formId}`, options);
    }

    public search(path: string, term: string): Observable<any> {
        return this.get(`${this.environment.publicFormsHostUrl}/${path}?q=${term}`);
    }

    public submit(formId: string, data: object): Observable<WebformSubmissionResponse> {
        return this.csrfToken().pipe(
            switchMap(
                (token: string): Observable<WebformSubmissionResponse> =>
                    this.post(`api/webform/submission/${formId}`, data, {
                        withCredentials: true,
                        headers: {
                            [ApiService.headerCsrf]: token,
                        },
                    }),
            ),
            // eslint-disable-next-line sonarjs/no-nested-assignment
            tap((response: WebformSubmissionResponse) => (response.error ? (this.submittedSuccessfully = false) : (this.submittedSuccessfully = true))),
            catchError((err: Error): Observable<never> => throwError(() => err)),
        );
    }

    public uploadFile(fileObject: UploadFile, file: File, fileComponentSettings: FormConfigElement): Observable<UploadFile> {
        return this.csrfToken().pipe(
            switchMap((token: string): Observable<any> => {
                const uploadUrl = this.buildUploadUrl(fileComponentSettings);

                return this.post(uploadUrl, file, {
                    reportProgress: true,
                    observe: 'events',
                    headers: {
                        [ApiService.headerCsrf]: token,
                        'Content-Type': 'application/octet-stream',
                        'Upload-Content-Type': file.type,
                        'Content-Disposition': `file; filename="${encodeURI(file.name)}"`,
                    },
                });
            }),
            map((event: any): UploadFile => {
                fileObject.status = event.type;

                switch (fileObject.status) {
                    case HttpEventType.UploadProgress:
                        fileObject.transferred = event.loaded;

                        // eslint-disable-next-line no-case-declarations
                        const progress: number = Math.round(((fileObject.transferred ?? 0) / fileObject.size) * this.hundred);
                        fileObject.progress = progress > this.hundred ? this.hundred : progress;

                        break;

                    case HttpEventType.Response:
                        fileObject.response = event.body;
                        break;

                    default:
                        fileObject.response = 'Unhandled event';
                        break;
                }

                return fileObject;
            }),
            catchError((err: Error): Observable<never> => throwError(() => err)),
        );
    }

    public buildUploadUrl(fileComponentSettings: FormConfigElement): string {
        const formPath: string[] = fileComponentSettings.formPath;

        const webformId: string = formPath[0];
        const webformPath: string = formPath.join('.');
        const fieldName: string = fileComponentSettings.name;
        const uploadId: string = fileComponentSettings.value.upload_id ?? 'regular';

        return `${this.environment.publicFormsHostUrl}/${this.environment.formApiPath}/${webformId}/${webformPath}/files/${fieldName}/${uploadId}`;
    }

    public deleteFile(fileObject: UploadFile, fileComponentSettings: FormConfigElement, headers: AuthorizationHeader = {}): Observable<any> {
        const formId: string = fileComponentSettings.formPath[0];
        const fieldName: string = fileComponentSettings.name;
        const uploadId: string = fileComponentSettings.value.upload_id ?? 'regular';
        const fileId: string | undefined = fileObject.fileId;

        if (fileId === undefined) {
            return throwError(() => 'delete file: file id is missing!');
        }

        const url = `${this.environment.publicFormsHostUrl}/${this.environment.formApiPath}/${formId}/file/${uploadId}/files/${fileId}`;

        return this.csrfToken().pipe(
            switchMap(
                (token: string): Observable<any> =>
                    this.delete(url, {
                        withCredentials: true,
                        params: {
                            fieldName,
                        },
                        headers: {
                            [ApiService.headerCsrf]: token,
                            ...headers,
                        },
                    }),
            ),
        );
    }

    public downloadFile(url: string): Observable<any> {
        return this.csrfToken().pipe(
            switchMap(
                (token: string): Observable<any> =>
                    this.get(url, {
                        withCredentials: true,
                        responseType: 'blob',
                        headers: {
                            [ApiService.headerCsrf]: token,
                            'ngsw-bypass': 'true',
                        },
                    }),
            ),
        );
    }

    private static finalizeOptions(options: RequestOptions, ngswByPass = false): void {
        options.withCredentials = true;

        if (!options.headers) {
            options.headers = {};
        }

        // eslint-disable-next-line no-extra-parens
        (options.headers as Record<string, string>)['Cache-Control'] = 'no-cache';

        if (ngswByPass) {
            (options.headers as Record<string, string>)['ngsw-bypass'] = 'true';
        }
    }

    private csrfToken(): Observable<any> {
        return this.get(`${this.environment.publicFormsHostUrl}/${this.environment.sessionTokenApiPath}`, {
            responseType: 'text',
        });
    }

    private get<T = any>(path: string, options: object = {}): Observable<T> {
        ApiService.finalizeOptions(options, true);

        return this.httpClient.get<T>(path, options);
    }

    private post(path: string, data: object, options: object): Observable<any> {
        ApiService.finalizeOptions(options, true);

        return this.httpClient.post(path, data, options);
    }

    private delete(path: string, options: object): Observable<any> {
        ApiService.finalizeOptions(options, true);

        return this.httpClient.delete(path, options);
    }
}
