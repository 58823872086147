import { HttpRequests, toQueryParams } from "./http-requests.js";
class Batch {
  uid;
  details;
  stats;
  startedAt;
  finishedAt;
  duration;
  progress;
  constructor(batch) {
    this.uid = batch.uid;
    this.details = batch.details;
    this.stats = batch.stats;
    this.startedAt = batch.startedAt;
    this.finishedAt = batch.finishedAt;
    this.duration = batch.duration;
    this.progress = batch.progress;
  }
}
class BatchClient {
  httpRequest;
  constructor(config) {
    this.httpRequest = new HttpRequests(config);
  }
  /**
   * Get one batch
   *
   * @param uid - Unique identifier of the batch
   * @returns
   */
  async getBatch(uid) {
    const url = `batches/${uid}`;
    const batch = await this.httpRequest.get(url);
    return new Batch(batch);
  }
  /**
   * Get batches
   *
   * @param parameters - Parameters to browse the batches
   * @returns Promise containing all batches
   */
  async getBatches(parameters = {}) {
    const url = `batches`;
    const batches = await this.httpRequest.get(url, toQueryParams(parameters));
    return {
      ...batches,
      results: batches.results.map(batch => new Batch(batch))
    };
  }
}
export { BatchClient, Batch };