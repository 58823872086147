import { Component, HostBinding, Input } from '@angular/core';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-hidden',
    templateUrl: './hidden.component.html',
    standalone: false,
})
export class HiddenComponent extends BaseComponent {
    @HostBinding('class') @Input() public override classList = 'block mb-0';

    // TODO @DF Check, if HiddenComponent is needed at all (https://public.brainbits.net/jira/browse/BIGDEV-3091)

    public static register(): ComponentMap {
        return {
            hidden: HiddenComponent,
        };
    }
}
