import { ChangeDetectionStrategy, Component, inject, type OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MobileAppRepository, type MobileAppState } from '@big-direkt/state/mobile-app';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-mobile-app-info',
    templateUrl: './mobile-app-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, UiFormRowComponent],
})
export class MobileAppInfoComponent extends BaseComponent implements OnInit {
    private readonly mobileAppRepository = inject(MobileAppRepository);

    public infoValue: boolean | string | undefined = undefined;

    public static register(): ComponentMap {
        return {
            big_webform_mobile_app_info: MobileAppInfoComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public ngOnInit(): void {
        const mobileAppState = this.mobileAppRepository.getValue();
        const key = this.settings.localStorageKey;

        if (key !== undefined && key in mobileAppState && mobileAppState.isEmbeddedInMobileApp) {
            this.infoValue = mobileAppState[key as keyof MobileAppState['mobileApp']];
            this.control?.setValue(this.infoValue);
        }
    }
}
