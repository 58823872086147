import { Component } from '@angular/core';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-composite',
    template: '<ng-template appElementHost></ng-template>',
    standalone: false,
})
export class CompositeComponent extends NestedValuesBaseComponent {
    public static register(): ComponentMap {
        return {
            big_webform_bank_data: CompositeComponent,  // eslint-disable-line @typescript-eslint/naming-convention
            big_webform_contact_data: CompositeComponent,  // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
