import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { type ErrorOverrideTemplate } from '../../../interfaces/error-override-template';
import { ComponentService } from '../../../services/component/component.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { type CheckboxComponent } from '../checkbox/checkbox.component';

const maxAllowedTemplate: ErrorOverrideTemplate<{ max: string }> = params => ({
    i18nKey: 'ftbForm.validation.maxAllowedInvalid',
    params: {
        max: params.max,
    },
});

@Component({
    selector: 'big-form-checkboxes',
    templateUrl: './checkboxes.component.html',
    providers: [provideTranslationScope('ftbCheckboxes', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    standalone: false,
})
export class CheckboxesComponent extends NestedValuesBaseComponent implements OnInit {
    public readonly componentService = inject(ComponentService);
    public readonly errorOverrides = {
        required: 'ftbCheckboxes.required',
        maxAllowedInvalid: maxAllowedTemplate,
    };

    private readonly destroyRef = inject(DestroyRef);
    private excludedOptions?: string[];

    public static register(): ComponentMap {
        return {
            checkboxes: CheckboxesComponent,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            big_webform_excludable_checkboxes: CheckboxesComponent,
        };
    }

    public override init(): void {
        super.init();

        if (this.settings.excludedOptions) {
            this.excludedOptions = this.extractExcludedOptions(this.settings.excludedOptions);
        }

        if (this.settings.attributes?.requiredError) {
            this.errorOverrides.required = this.settings.attributes.requiredError;
        }
    }

    public ngOnInit(): void {
        this.settings.children.forEach((item: FormConfigElement): void => {
            const { instance: component } = this.componentService.createComponent<CheckboxComponent>(item);
            this.componentService.insertViewChildren([item], this.elementHost?.viewContainerRef);

            if (this.excludedOptions?.length) {
                this.bindExcludedCheckboxes(item, component);
            }

            component.classList = 'block [&:not(:last-child)]:mb-2';
            component.isSingleItem = false;
        });
    }

    private extractExcludedOptions(optionsString: string): string[] {
        return optionsString
            .split(',')
            .map((entry: string) => entry.trim())
            .filter((entry: string) => entry !== '');
    }

    // TODO: Write test for this special case or integration test
    private bindExcludedCheckboxes(item: FormConfigElement, component: CheckboxComponent): void {
        if (item.returnValue && !this.excludedOptions?.includes(item.returnValue.toString())) {
            return;
        }

        component.control?.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((value: boolean) => {
            this.settings.children.forEach((subItem: FormConfigElement) => {
                const childControl = this.control?.get(subItem.name);
                if (
                    childControl &&
                    // eslint-disable-next-line sonarjs/different-types-comparison
                    component.control !== childControl &&
                    !!subItem.returnValue &&
                    this.excludedOptions?.includes(subItem.returnValue.toString())
                ) {
                    if (value) {
                        childControl.disable({ emitEvent: false });
                    } else {
                        childControl.enable({ emitEvent: false });
                    }

                    childControl.markAsUntouched();
                }
            });
        });
    }
}
