import { Component, HostBinding, Input, inject, isDevMode } from '@angular/core';
import { ScrollService } from '@big-direkt/utils/environment';
import { type PageHierarchyFormNode } from '../../services/page-navigation/page-hierarchy-node';
import { PageNavigationService } from '../../services/page-navigation/page-navigation.service';

@Component({
    selector: 'big-form-wizard-base',
    template: '',
})
export class WizardBaseComponent {
    public readonly pageNavigationService = inject(PageNavigationService);
    public readonly scrollService = inject(ScrollService);

    @HostBinding('class') @Input() public classList = 'block';

    public get pages(): PageHierarchyFormNode[] {
        return this.pageNavigationService.pages;
    }

    public get currentPage(): PageHierarchyFormNode | undefined {
        return this.pageNavigationService.getCurrentPage();
    }

    public get wizardPageCount(): number {
        return this.pageNavigationService.wizardPageCount;
    }

    public onClick(page: PageHierarchyFormNode): void {
        if (!isDevMode() || !page.firstAccessibleLeaf) {
            return;
        }

        this.pageNavigationService.gotoPage(page.firstAccessibleLeaf);
        this.scrollService.scroll();
    }

    public isPageActive(page: PageHierarchyFormNode): boolean {
        if (!this.currentPage) {
            throw new Error('wizard: current page missing!');
        }

        return page === this.currentPage || page.hasDescendant(this.currentPage);
    }
}
