import { formatDate, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, HostBinding, inject, input, LOCALE_ID, Output, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumKalender, IconComponent } from '@big-direkt/utils/icons';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { IMaskDirective } from 'angular-imask';

import IMask from 'imask';
import 'imask/masked/date';

const MAX_YEAR = 9999;
type DateBoundaries = string | Date | undefined;

@Component({
    selector: 'big-ui-date',
    templateUrl: './date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FormsModule, IconComponent, IMaskDirective, MatFormFieldModule, MatDatepickerModule, MatInput, NgClass, ReactiveFormsModule, TranslocoDirective],
    providers: [
        provideNativeDateAdapter(),
        provideTranslationScope('uiDate', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`)),
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateComponent,
            multi: true,
        },
    ],
})
export class DateComponent extends BaseControlValueAccessor<string> {
    private readonly local = inject(LOCALE_ID);
    public readonly datePickerControl = new FormControl('', []);
    public readonly formControlChanges = toSignal(this.formControl.valueChanges);
    public readonly hasFocus = signal(false);
    public readonly iconCalendar = IconBigMediumKalender;
    public readonly id = input.required<string>();
    public readonly isInvalid = input.required<boolean>();
    public readonly isRequired = input.required<boolean>();
    public readonly isValid = input<boolean>(false);
    public readonly maxDate = input<Date | undefined, DateBoundaries>(undefined, {
        transform: (value: DateBoundaries): Date | undefined => (value ? new Date(value) : undefined),
    });
    public readonly minDate = input<Date, DateBoundaries>(this.defaultMinDate(), {
        transform: (value: DateBoundaries): Date => (value ? new Date(value) : this.defaultMinDate()),
    });
    public readonly showValidation = input<boolean>(false);

    @Output() public readonly errors = new EventEmitter<Record<string, unknown> | null>();

    @HostBinding('class') public classList = 'block';

    @ViewChild('datePicker') public datePicker!: MatDatepicker<Date>;

    public dateMask = IMask.createMask({
        mask: Date,
        pattern: 'd{.}`m{.}`y',
        blocks: {
            d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
            },
            m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
            },
            y: {
                mask: IMask.MaskedRange,
                from: 0,
                to: MAX_YEAR,
                maxLength: 4,
            },
        },
    });

    public constructor() {
        super();

        effect(
            () => {
                // eslint-disable-next-line no-null/no-null
                const value = this.formControlChanges() ?? null;
                const [day, month, year] = value?.split('.') ?? [];

                this.datePickerControl.setValue(`${year}-${month}-${day}`);
                this.datePickerControl.updateValueAndValidity();
                this.formControl.updateValueAndValidity();
                this.onChange(value);
                this.emitErrors();
            },
        );
    }

    public openDatepicker(event: Event): void {
        event.preventDefault();

        this.datePicker.open();
    }

    public datePickerClose(): void {
        const dateValue = this.datePickerControl.value;

        if (!dateValue) {
            this.formControl.setValue('');

            return;
        }

        try {
            const dateObject = new Date(dateValue);

            this.formControl.setValue(formatDate(dateObject, 'dd.MM.YYYY', this.local));
        } catch {
            this.formControl.setValue('');

            return;
        }
    }

    private emitErrors(): void {
        const errors = {
            ...this.datePickerControl.errors,
            ...this.formControl.errors,
            ...(!this.formControl.value && this.isRequired() && { required: true }),
        };

        if (!this.isRequired() && !this.formControl.value) {
            // eslint-disable-next-line no-null/no-null
            this.errors.emit(null);

            return;
        }

        // eslint-disable-next-line no-null/no-null
        this.errors.emit(Object.keys(errors).length ? errors : null);
    }

    private defaultMinDate(): Date {
        const hundredYears = 100;
        const currentDate = new Date();

        currentDate.setFullYear(currentDate.getFullYear() - hundredYears);

        return currentDate;
    }
}
