<ng-container *transloco="let t; prefix: 'ftbTextfield'">
    <big-ui-form-row
        [id]="settings.id"
        [label]="label"
        [isRequired]="control?.isRequired"
        [errors]="control?.errors"
        [showError]="isInvalid"
        [showInfo]="!!settings.help"
        [errorOverrides]="errorOverrides"
        [infoTextTemplate]="help"
        (infoLayerOpened)="trackFormsInfoIconEvent($event)">
        <input
            class="form-control"
            #myInput
            bigSharedTrimOnBlur
            [id]="settings.id"
            [attr.disabled]="isDisabled"
            type="text"
            [attr.readonly]="isReadOnly"
            [imask]="mask"
            [formControl]="control"
            [ngClass]="{
                'is-valid': isValid,
                'is-invalid': isInvalid
            }"
            [appDrupalWebformElementAttributes]="settings"
            [formFieldTracking]="settings" />
    </big-ui-form-row>
</ng-container>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
