import { Component, Input } from '@angular/core';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-time',
    styleUrl: './time.component.scss',
    templateUrl: './time.component.html',
    standalone: false,
})
export class TimeComponent extends TextfieldBaseComponent {
    @Input() public showErrorsOnMinimal = false;
    @Input() public noMargin = false;

    public static register(): ComponentMap {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            webform_time: TimeComponent,
        };
    }
}
