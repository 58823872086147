class EnqueuedTask {
  taskUid;
  indexUid;
  status;
  type;
  enqueuedAt;
  constructor(task) {
    this.taskUid = task.taskUid;
    this.indexUid = task.indexUid;
    this.status = task.status;
    this.type = task.type;
    this.enqueuedAt = new Date(task.enqueuedAt);
  }
}
export { EnqueuedTask };