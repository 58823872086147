import { Component, DestroyRef, EventEmitter, HostBinding, Output, inject } from '@angular/core';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-multiple-item',
    templateUrl: './multiple-item.component.html',
    standalone: false,
})
export class MultipleItemComponent extends NestedValuesBaseComponent {
    @HostBinding('class') public override classList = 'block mt-12 first:mt-0';
    @Output() public readonly delete: EventEmitter<MultipleItemComponent> = new EventEmitter<MultipleItemComponent>();
    @Output() public readonly add: EventEmitter<MultipleItemComponent> = new EventEmitter<MultipleItemComponent>();
    public destroyRef = inject(DestroyRef);
    public isFirst = false;
    public isLast = false;

    public static register(): ComponentMap {
        return {
            webform_multiple_item: MultipleItemComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public doDelete(): void {
        this.delete.emit(this);
    }

    public doAdd(): void {
        this.add.emit(this);
    }
}
