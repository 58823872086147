import { Component, inject, LOCALE_ID } from '@angular/core';
import IMask from 'imask/holder';
import 'imask/masked/number';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-number',
    templateUrl: './numberfield.component.html',
    standalone: false,
})
export class NumberfieldComponent extends InputBaseComponent {
    public readonly locale = inject(LOCALE_ID);

    public numberMask = IMask.createMask({
        mask: Number,
        scale: 2,
        thousandsSeparator: '.',
        radix: ',',
    });

    public static register(): ComponentMap {
        return {
            number: NumberfieldComponent,
        };
    }
}
