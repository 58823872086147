
export const dateRangeStringToObject = (dateString: string | null | undefined): { start: Date | null, end: Date | null } => {
    const regexPattern = /(\d{2}\.\d{2}\.\d{4})?\s*-?\s*(\d{2}\.\d{2}\.\d{4})?/;
    const match = regexPattern.exec(dateString ?? '');

    const startDateIndex = 1;
    const endDateIndex = 2;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const [startDay, startMonth, startYear] = match?.[startDateIndex]?.split('.') ?? [];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const [endDay, endMonth, endYear] = match?.[endDateIndex]?.split('.') ?? [];

    // eslint-disable-next-line no-null/no-null
    const start = startDay && startMonth && startYear ? new Date(`${startYear}-${startMonth}-${startDay}`) : null;
    // eslint-disable-next-line no-null/no-null
    const end = endDay && endMonth && endYear ? new Date(`${endYear}-${endMonth}-${endDay}`) : null;

    return {
        start,
        end,
    }
}
