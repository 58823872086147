import { Component, HostBinding, Input, inject, type OnInit } from '@angular/core';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { type BigAnyFormControl } from '../../../form-control/big-any-form-control';
import { FormService } from '../../../services/form/form.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-label',
    templateUrl: './label.component.html',
    providers: [provideTranslationScope('ftbLabel', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    standalone: false,
})
export class LabelComponent implements OnInit {
    private readonly formService = inject(FormService);

    @Input() public settings?: FormConfigElement | null;
    @Input() public for?: string;

    @HostBinding('class') @Input() public classList = '';

    public control?: BigAnyFormControl;

    @HostBinding('class.hidden')
    public get isHidden(): boolean {
        return this.settings === undefined;
    }

    public get inputId(): string | undefined {
        if (this.for !== undefined) {
            return this.for;
        }

        if (!this.settings) {
            return undefined;
        }

        return this.settings.id;
    }

    public get required(): boolean {
        if (this.control) {
            return this.control.isRequired;
        }

        return this.settings?.required ?? false;
    }

    public ngOnInit(): void {
        this.control = this.formService.getFormControl(this.settings?.arrayParents ?? []);
    }

    // I don't find any "label" in configs. Do we need this register function?
    public static register(): ComponentMap {
        return {
            label: LabelComponent,
        };
    }
}
