/* eslint-disable @angular-eslint/template/cyclomatic-complexity, @angular-eslint/template/no-call-expression */
import { NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { IconBigMediumOk, IconComponent } from '@big-direkt/utils/icons';

@Component({
    selector: 'big-form-wizard-step-item',
    templateUrl: './wizard-step-item.component.html',
    imports: [NgClass, IconComponent],
})
export class WizardStepItemComponent {
    public readonly iconOk = IconBigMediumOk;

    @Input() public active = false;
    @Input() public page?: number;
    @Input() public isLast = false;

    @HostBinding('class') @Input() public classList = 'inline-flex';
}
