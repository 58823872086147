import { ChangeDetectorRef, Component, QueryList, ViewChildren, type OnInit } from '@angular/core';
import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';
import { FormConfigElement, WEEKDAYS, type AllowedDrupalType } from '@big-direkt/form/contracts';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { ElementHostDirective } from '../../../directives/element-host/element-host.directive';
import { type BigUntypedFormGroup } from '../../../form-control/big-form-group';
import { ComponentService } from '../../../services/component/component.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { type TimeComponent } from '../time/time.component';

@Component({
    selector: 'big-form-time-range',
    templateUrl: './time-range.component.html',
    standalone: false,
})
export class TimeRangeComponent extends NestedValuesBaseComponent implements OnInit {
    @ViewChildren(ElementHostDirective) private readonly childContainers!: QueryList<ElementHostDirective>;

    public constructor(
        public readonly changeDetector: ChangeDetectorRef,
        public readonly componentService: ComponentService,
    ) {
        super();
    }

    public static register(): ComponentMap {
        return {
            big_webform_time_range: TimeRangeComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public static validateTimeRange(startSettings: FormConfigElement, endSettings: FormConfigElement, affected: 'isEndTime' | 'isStartTime'): ValidatorFn {
        /* eslint-disable no-null/no-null */
        return (control: AbstractControl): ValidationErrors | null => {
            const parent = control.parent as BigUntypedFormGroup;
            const startControl = parent.get(startSettings.name);
            const endControl = parent.get(endSettings.name);

            if (!startControl || !endControl) {
                return null;
            }

            if (!startControl.value && !endControl.value) {
                startControl.setErrors(null);
                endControl.setErrors(null);

                return null;
            }

            // Start was set but not end
            if (!!startControl.value && !endControl.value) {
                endControl.setErrors({
                    notBeEmpty: true,
                });

                return null;
            }

            // End was set but not start
            if (!startControl.value && !!endControl.value) {
                startControl.setErrors({
                    notBeEmpty: true,
                });

                return null;
            }

            // Start is larger than end
            if (startControl.value >= endControl.value) {
                const key = affected === 'isStartTime' ? 'mustBeSmaller' : 'mustBeLarger';

                return { [key]: true };
            }

            // All has passed so remove errors on related fields
            startControl.setErrors(null);
            endControl.setErrors(null);

            return null;
        };
        /* eslint-enable no-null/no-null */
    }

    public override init(): void {
        super.init();
        this.componentService.registerForManualChildHandling(Object.keys(TimeRangeComponent.register()) as AllowedDrupalType[]);
    }

    public ngOnInit(): void {
        // TODO: Refactor component creation so that this ChangeDetector is not needed (bad practice).
        this.changeDetector.detectChanges();

        this.settings.children.forEach((childSettings: FormConfigElement): void => {
            const childRef: ElementHostDirective | undefined = this.childContainers.find(({ id }: ElementHostDirective): boolean => id === childSettings.name);

            if (!childRef) {
                return;
            }

            this.formService.createControls(childSettings);
            const { instance: component } = this.componentService.createComponent<TimeComponent>(childSettings);
            this.componentService.insertViewChildren([childSettings], childRef.viewContainerRef);

            component.minimal = true;
            component.showErrorsOnMinimal = true;
            component.noMargin = true;
        });
    }

    public isStartItem(componentName: string): boolean {
        return componentName.includes('start');
    }

    public displayWeekday(weekdayIndex: string | undefined): string {
        if (weekdayIndex === undefined) {
            return '';
        }

        return WEEKDAYS[Number(weekdayIndex)];
    }
}
