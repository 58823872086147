import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, signal, WritableSignal, type OnChanges, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JsonApiClientService, ResourceTypes as JsonApiResourceTypes } from '@big-direkt/json-api-client';
import { ErrorHandlingService } from '@big-direkt/utils/environment';
import { type ParagraphAccordionModel } from '@big-direkt/utils/shared';
import { BehaviorSubject, of, type Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'big-form-faq',
    templateUrl: './faq.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class FaqComponent implements OnInit, OnChanges {
    private readonly accordionId: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    private readonly destroyRef = inject(DestroyRef);

    @Input() public faqNodeId?: string;

    public accordion: WritableSignal<ParagraphAccordionModel | undefined> = signal(undefined);
    public isLoading: WritableSignal<boolean> = signal(true);
    public hasErrors: WritableSignal<boolean> = signal(false);

    public constructor(
        private readonly jsonapiClient: JsonApiClientService,
        private readonly errorHandlingService: ErrorHandlingService,
    ) {}

    public ngOnChanges(): void {
        this.accordionId.next(this.faqNodeId);
    }

    public ngOnInit(): void {
        this.accordionId
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => {
                    this.isLoading.set(true);
                }),
                switchMap((nodeId: string | undefined): Observable<ParagraphAccordionModel | undefined> => {
                    if (nodeId === undefined || this.faqNodeId === undefined) {
                        return of(undefined);
                    }

                    return this.jsonapiClient.load<ParagraphAccordionModel>(this.faqNodeId, JsonApiResourceTypes.NodeAccordion);
                }),
                tap((accordion: ParagraphAccordionModel | undefined): void => {
                    this.isLoading.set(false);
                    this.accordion.set(accordion);
                }),
                catchError(error => {
                    this.hasErrors.set(true);
                    this.isLoading.set(false);
                    this.errorHandlingService.catchError(error);

                    return error;
                }),
            )
            .subscribe();
    }
}
