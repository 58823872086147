import { Component, HostBinding, Input, SecurityContext } from '@angular/core';
import { IconBigMediumPfeilRechts } from '@big-direkt/utils/icons';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-alert',
    templateUrl: './alert.component.html',
    standalone: false,
})
export class AlertComponent extends BaseComponent {
    public readonly markupSecurity: SecurityContext = SecurityContext.HTML;
    public readonly iconArrowRight = IconBigMediumPfeilRechts;

    @HostBinding('class') @Input() public override classList = 'block [&:not(:last-child)]:mb-20';

    public static register(): ComponentMap {
        return {
            big_webform_alert: AlertComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
