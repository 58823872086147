import { Pipe, type PipeTransform } from '@angular/core';
import { type PageHierarchyFormNode } from '../../services/page-navigation/page-hierarchy-node';

@Pipe({
    name: 'wizardVisible',
    // eslint-disable-next-line @angular-eslint/no-pipe-impure
    pure: false,
})
export class WizardVisiblePipe implements PipeTransform {
    public transform(pages: PageHierarchyFormNode[]): PageHierarchyFormNode[] {
        return pages.filter((page: PageHierarchyFormNode): boolean => page.hasWizardEntry && page.isAccessible);
    }
}
