import { Component } from '@angular/core';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-item',
    templateUrl: './item.component.html',
    standalone: false,
})
export class ItemComponent extends BaseComponent {
    public override get isHidden(): boolean {
        return !this.control || this.control.disabled || this.settings.markup === undefined;
    }

    public static register(): ComponentMap {
        return {
            item: ItemComponent,
        };
    }
}
