import { Directive, ElementRef, Inject, Input, Renderer2, type OnInit } from '@angular/core';
import { FormConfigElement } from '@big-direkt/form/contracts';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[appDrupalWebformElementAttributes]',
})
export class BaseAttributesDirective implements OnInit {
    @Input('appDrupalWebformElementAttributes') public settings?: FormConfigElement;

    public constructor(
        @Inject(ElementRef) private readonly elementRef: ElementRef,
        @Inject(Renderer2) private readonly renderer: Renderer2,
    ) {
    }

    public ngOnInit(): void {
        if (!this.settings) {
            return;
        }

        const allowedStandardAttributes: string[] = [
            'min',
            'max',
            'placeholder',
            'step',
            'maxlength',
            'size',
            'inputmode',
        ];

        allowedStandardAttributes.forEach((attribute: string): void => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.settings?.hasOwnProperty(attribute) === true) {
                // TODO: fix ts lint error !!!
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error TS7053
                this.renderer.setAttribute(this.elementRef.nativeElement, attribute, this.settings[attribute]);
            }
        });

        const allowedCustomAttributes: string[] = [
            'autocapitalize',
            'autocorrect',
            'spellcheck',
            'autocomplete',
            'min',
            'max',
        ];

        allowedCustomAttributes.forEach((attribute: string): void => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.settings?.hasOwnProperty('attributes') === true && this.settings.attributes?.hasOwnProperty(attribute) === true) {
                // TODO: fix ts lint error !!!
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error TS7053
                this.renderer.setAttribute(this.elementRef.nativeElement, attribute, this.settings.attributes[attribute]);
            }
        });
    }
}
