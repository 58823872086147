<big-ui-form-row
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <input
        class="form-control"
        [id]="settings.id"
        type="text"
        [imask]="numberMask"
        [formControl]="control"
        [ngClass]="{
            'is-valid': isValid,
            'is-invalid': isInvalid
        }"
        [appDrupalWebformElementAttributes]="settings"
        [formFieldTracking]="settings" />
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
