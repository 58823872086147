import { Component } from '@angular/core';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form',
    template: '<ng-template appElementHost></ng-template>',
    standalone: false,
})
export class FormComponent extends StructureBaseComponent {
    public constructor() {
        super();
    }

    public static register(): ComponentMap {
        return {
            form: FormComponent,
        };
    }
}
