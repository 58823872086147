import { NgClass } from '@angular/common';
import { NgModule, inject, type Type, provideEnvironmentInitializer } from '@angular/core';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { IconComponent } from '@big-direkt/utils/icons';
import { IsArrayPipe } from '@big-direkt/utils/shared';
import { TranslocoModule } from '@jsverse/transloco';
import { PreviewVisiblePipe } from '../pipes/preview-visible/preview-visible.pipe';
import { ReplaceTokenRefPipe } from '../pipes/replace-token-ref/replace-token-ref.pipe';
import { StringReplacePipe } from '../pipes/string-replace/string-replace.pipe';
import { DrupalWebformService } from '../services/drupal-webform/drupal-webform.service';
import { PreviewElementComponent } from './preview-element/preview-element.component';
import { PreviewGroupComponent } from './preview-group/preview-group.component';
import { PreviewComponent } from './preview.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const entryComponents: Type<any>[] = [PreviewComponent];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const declarations: Type<any>[] = [PreviewVisiblePipe, PreviewElementComponent, PreviewGroupComponent, ...entryComponents];

@NgModule({
    declarations,
    imports: [IconComponent, IsArrayPipe, NgClass, ReplaceTokenRefPipe, StringReplacePipe, TranslocoModule, UiAlertComponent, UiButtonComponent],
    exports: declarations,
    providers: [
        provideEnvironmentInitializer((): void => {
            inject(DrupalWebformService).registerComponents(entryComponents);
        }),
        // PreviewVisiblePipe,
    ],
})
export class PreviewModule {}
