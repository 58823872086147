import { Component } from '@angular/core';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-elements-holder',
    templateUrl: './elements-holder.component.html',
    standalone: false,
})
export class ElementsHolderComponent extends StructureBaseComponent {
    public static register(): ComponentMap {
        return {
            webform_custom_composite: ElementsHolderComponent,  // eslint-disable-line @typescript-eslint/naming-convention
            webform_contact: ElementsHolderComponent,  // eslint-disable-line @typescript-eslint/naming-convention
            webform_entity_checkboxes: ElementsHolderComponent,  // eslint-disable-line @typescript-eslint/naming-convention
            webform_entity_radios: ElementsHolderComponent,  // eslint-disable-line @typescript-eslint/naming-convention
            webform_entity_select: ElementsHolderComponent,  // eslint-disable-line @typescript-eslint/naming-convention
            webform_link: ElementsHolderComponent,  // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
