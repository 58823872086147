import { formatDate } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IconBigMediumDateiDownload } from '@big-direkt/utils/icons';
import { ExternalEventService } from '@big-direkt/utils/shared';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type WebformSubmissionDataResponse } from '../../../interfaces/webform-submission-response-data';
import { FormStateService } from '../../../services/form-state/form-state.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-pdf-download',
    templateUrl: './pdf-download.component.html',
    standalone: false,
})
export class PdfDownloadComponent extends BaseComponent {
    public readonly externalEventService = inject(ExternalEventService);
    public readonly formStateService = inject(FormStateService);
    public readonly iconDownload = IconBigMediumDateiDownload;

    public get pdfDownloadLink(): string | undefined {
        const response = this.formStateService.getResponse();

        if (!response) {
            return undefined;
        }

        if (Array.isArray(response.data)) {
            return this.getDownloadLinkFromArray(response.data, this.settings.multiplePdfId ?? '');
        }

        return typeof response.data?.links?.self === 'string' ? response.data.links.self : undefined;
    }

    public get filename(): string {
        const response = this.formStateService.getResponse();

        if (!response?.data) {
            return this.defaultFileName();
        }

        if (Array.isArray(response.data)) {
            return this.getFileNameFromArray(response.data, this.settings.multiplePdfId ?? '') ?? this.defaultFileName();
        }

        return response.data.attributes?.file_name ?? this.defaultFileName();
    }

    public static register(): ComponentMap {
        return {
            webform_pdf_download: PdfDownloadComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public onClick($event: Event): void {
        if (this.formService.pdfDownloadClickDisabled) {
            $event.preventDefault();

            this.externalEventService.dispatch('clickPdfDownload', {
                url: this.pdfDownloadLink,
            });
        }
    }

    private getDownloadLinkFromArray(data: WebformSubmissionDataResponse[], id: string): string | undefined {
        const match = data.find(entry => typeof entry.links?.self !== 'string' && entry.links?.self.meta?.multiple_pdf_id === id);

        if (!match?.links || typeof match.links.self === 'string') {
            return undefined;
        }

        return match.links.self.href;
    }

    private getFileNameFromArray(data: WebformSubmissionDataResponse[], id: string): string | undefined {
        const match = data.find(entry => typeof entry.links?.self !== 'string' && entry.links?.self.meta?.multiple_pdf_id === id);

        return match?.attributes?.file_name;
    }

    private defaultFileName(): string {
        const title = this.formStateService.getForm()?.webformTitle?.replace(' ', '-') ?? this.formStateService.getForm()?.id ?? '';
        const date = formatDate(new Date(), 'YYYY-MM-dd-HH-mm', 'de-DE');

        return `big-direkt-${date}-${title}.pdf`;
    }
}
