/*
 * Bundle: MeiliSearch
 * Project: MeiliSearch - Javascript API
 * Author: Quentin de Quelen <quentin@meilisearch.com>
 * Copyright: 2019, MeiliSearch
 */
import { Index } from "./indexes.js";
import { ErrorStatusCode } from "./types.js";
import { HttpRequests } from "./http-requests.js";
import { TaskClient } from "./task.js";
import { EnqueuedTask } from "./enqueued-task.js";
import { BatchClient } from "./batch.js";
export class MeiliSearch {
  config;
  httpRequest;
  tasks;
  batches;
  /**
   * Creates new MeiliSearch instance
   *
   * @param config - Configuration object
   */
  constructor(config) {
    this.config = config;
    this.httpRequest = new HttpRequests(config);
    this.tasks = new TaskClient(config);
    this.batches = new BatchClient(config);
  }
  /**
   * Return an Index instance
   *
   * @param indexUid - The index UID
   * @returns Instance of Index
   */
  index(indexUid) {
    return new Index(this.config, indexUid);
  }
  /**
   * Gather information about an index by calling MeiliSearch and return an
   * Index instance with the gathered information
   *
   * @param indexUid - The index UID
   * @returns Promise returning Index instance
   */
  async getIndex(indexUid) {
    return new Index(this.config, indexUid).fetchInfo();
  }
  /**
   * Gather information about an index by calling MeiliSearch and return the raw
   * JSON response
   *
   * @param indexUid - The index UID
   * @returns Promise returning index information
   */
  async getRawIndex(indexUid) {
    return new Index(this.config, indexUid).getRawInfo();
  }
  /**
   * Get all the indexes as Index instances.
   *
   * @param parameters - Parameters to browse the indexes
   * @returns Promise returning array of raw index information
   */
  async getIndexes(parameters = {}) {
    const rawIndexes = await this.getRawIndexes(parameters);
    const indexes = rawIndexes.results.map(index => new Index(this.config, index.uid, index.primaryKey));
    return {
      ...rawIndexes,
      results: indexes
    };
  }
  /**
   * Get all the indexes in their raw value (no Index instances).
   *
   * @param parameters - Parameters to browse the indexes
   * @returns Promise returning array of raw index information
   */
  async getRawIndexes(parameters = {}) {
    const url = `indexes`;
    return await this.httpRequest.get(url, parameters);
  }
  /**
   * Create a new index
   *
   * @param uid - The index UID
   * @param options - Index options
   * @returns Promise returning Index instance
   */
  async createIndex(uid, options = {}) {
    return await Index.create(uid, options, this.config);
  }
  /**
   * Update an index
   *
   * @param uid - The index UID
   * @param options - Index options to update
   * @returns Promise returning Index instance after updating
   */
  async updateIndex(uid, options = {}) {
    return await new Index(this.config, uid).update(options);
  }
  /**
   * Delete an index
   *
   * @param uid - The index UID
   * @returns Promise which resolves when index is deleted successfully
   */
  async deleteIndex(uid) {
    return await new Index(this.config, uid).delete();
  }
  /**
   * Deletes an index if it already exists.
   *
   * @param uid - The index UID
   * @returns Promise which resolves to true when index exists and is deleted
   *   successfully, otherwise false if it does not exist
   */
  async deleteIndexIfExists(uid) {
    try {
      await this.deleteIndex(uid);
      return true;
    } catch (e) {
      if (e.code === ErrorStatusCode.INDEX_NOT_FOUND) {
        return false;
      }
      throw e;
    }
  }
  /**
   * Swaps a list of index tuples.
   *
   * @param params - List of indexes tuples to swap.
   * @returns Promise returning object of the enqueued task
   */
  async swapIndexes(params) {
    const url = "/swap-indexes";
    return await this.httpRequest.post(url, params);
  }
  ///
  /// Multi Search
  ///
  /**
   * Perform multiple search queries.
   *
   * It is possible to make multiple search queries on the same index or on
   * different ones
   *
   * @example
   *
   * ```ts
   * client.multiSearch({
   *   queries: [
   *     { indexUid: "movies", q: "wonder" },
   *     { indexUid: "books", q: "flower" },
   *   ],
   * });
   * ```
   *
   * @param queries - Search queries
   * @param config - Additional request configuration options
   * @returns Promise containing the search responses
   */
  async multiSearch(queries, config) {
    const url = `multi-search`;
    return await this.httpRequest.post(url, queries, undefined, config);
  }
  ///
  /// TASKS
  ///
  /**
   * Get the list of all client tasks
   *
   * @param parameters - Parameters to browse the tasks
   * @returns Promise returning all tasks
   */
  async getTasks(parameters = {}) {
    return await this.tasks.getTasks(parameters);
  }
  /**
   * Get one task on the client scope
   *
   * @param taskUid - Task identifier
   * @returns Promise returning a task
   */
  async getTask(taskUid) {
    return await this.tasks.getTask(taskUid);
  }
  /**
   * Wait for multiple tasks to be finished.
   *
   * @param taskUids - Tasks identifier
   * @param waitOptions - Options on timeout and interval
   * @returns Promise returning an array of tasks
   */
  async waitForTasks(taskUids, {
    timeOutMs = 5000,
    intervalMs = 50
  } = {}) {
    return await this.tasks.waitForTasks(taskUids, {
      timeOutMs,
      intervalMs
    });
  }
  /**
   * Wait for a task to be finished.
   *
   * @param taskUid - Task identifier
   * @param waitOptions - Options on timeout and interval
   * @returns Promise returning an array of tasks
   */
  async waitForTask(taskUid, {
    timeOutMs = 5000,
    intervalMs = 50
  } = {}) {
    return await this.tasks.waitForTask(taskUid, {
      timeOutMs,
      intervalMs
    });
  }
  /**
   * Cancel a list of enqueued or processing tasks.
   *
   * @param parameters - Parameters to filter the tasks.
   * @returns Promise containing an EnqueuedTask
   */
  async cancelTasks(parameters) {
    return await this.tasks.cancelTasks(parameters);
  }
  /**
   * Delete a list of tasks.
   *
   * @param parameters - Parameters to filter the tasks.
   * @returns Promise containing an EnqueuedTask
   */
  async deleteTasks(parameters = {}) {
    return await this.tasks.deleteTasks(parameters);
  }
  /**
   * Get all the batches
   *
   * @param parameters - Parameters to browse the batches
   * @returns Promise returning all batches
   */
  async getBatches(parameters = {}) {
    return await this.batches.getBatches(parameters);
  }
  /**
   * Get one batch
   *
   * @param uid - Batch identifier
   * @returns Promise returning a batch
   */
  async getBatch(uid) {
    return await this.batches.getBatch(uid);
  }
  ///
  /// KEYS
  ///
  /**
   * Get all API keys
   *
   * @param parameters - Parameters to browse the indexes
   * @returns Promise returning an object with keys
   */
  async getKeys(parameters = {}) {
    const url = `keys`;
    const keys = await this.httpRequest.get(url, parameters);
    keys.results = keys.results.map(key => ({
      ...key,
      createdAt: new Date(key.createdAt),
      updatedAt: new Date(key.updatedAt)
    }));
    return keys;
  }
  /**
   * Get one API key
   *
   * @param keyOrUid - Key or uid of the API key
   * @returns Promise returning a key
   */
  async getKey(keyOrUid) {
    const url = `keys/${keyOrUid}`;
    return await this.httpRequest.get(url);
  }
  /**
   * Create one API key
   *
   * @param options - Key options
   * @returns Promise returning a key
   */
  async createKey(options) {
    const url = `keys`;
    return await this.httpRequest.post(url, options);
  }
  /**
   * Update one API key
   *
   * @param keyOrUid - Key
   * @param options - Key options
   * @returns Promise returning a key
   */
  async updateKey(keyOrUid, options) {
    const url = `keys/${keyOrUid}`;
    return await this.httpRequest.patch(url, options);
  }
  /**
   * Delete one API key
   *
   * @param keyOrUid - Key
   * @returns
   */
  async deleteKey(keyOrUid) {
    const url = `keys/${keyOrUid}`;
    return await this.httpRequest.delete(url);
  }
  ///
  /// HEALTH
  ///
  /**
   * Checks if the server is healthy, otherwise an error will be thrown.
   *
   * @returns Promise returning an object with health details
   */
  async health() {
    const url = `health`;
    return await this.httpRequest.get(url);
  }
  /**
   * Checks if the server is healthy, return true or false.
   *
   * @returns Promise returning a boolean
   */
  async isHealthy() {
    try {
      const url = `health`;
      await this.httpRequest.get(url);
      return true;
    } catch {
      return false;
    }
  }
  ///
  /// STATS
  ///
  /**
   * Get the stats of all the database
   *
   * @returns Promise returning object of all the stats
   */
  async getStats() {
    const url = `stats`;
    return await this.httpRequest.get(url);
  }
  ///
  /// VERSION
  ///
  /**
   * Get the version of MeiliSearch
   *
   * @returns Promise returning object with version details
   */
  async getVersion() {
    const url = `version`;
    return await this.httpRequest.get(url);
  }
  ///
  /// DUMPS
  ///
  /**
   * Creates a dump
   *
   * @returns Promise returning object of the enqueued task
   */
  async createDump() {
    const url = `dumps`;
    const task = await this.httpRequest.post(url);
    return new EnqueuedTask(task);
  }
  ///
  /// SNAPSHOTS
  ///
  /**
   * Creates a snapshot
   *
   * @returns Promise returning object of the enqueued task
   */
  async createSnapshot() {
    const url = `snapshots`;
    const task = await this.httpRequest.post(url);
    return new EnqueuedTask(task);
  }
}