import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, type OnInit } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiLabelComponent } from '@big-direkt/ui/label';
import { BaseControlValueAccessor, Nl2brPipe } from '@big-direkt/utils/shared';
import { type RadioItem } from '../models/radio-item.model';
import { RadioComponent } from './radio.component';

@Component({
    selector: 'big-ui-radios',
    templateUrl: './radios.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UiLabelComponent, UiFormRowComponent, NgClass, Nl2brPipe, ReactiveFormsModule, RadioComponent],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RadiosComponent,
            multi: true,
        },
    ],
})
export class RadiosComponent extends BaseControlValueAccessor<string> implements OnInit {
    @Input({ required: true }) public id!: string;
    @Input({ required: true }) public name!: string;
    @Input({ required: true }) public items!: RadioItem[];
    @Input() public isRequired = false;
    @Input() public isDisabled = false;
    @Input() public isValid = false;
    @Input() public isInvalid = false;
    @Input() public selected?: string;
    @Output() public readonly infoLayerOpened = new EventEmitter<{ isOpen: boolean; fieldName: string; required: boolean }>();

    @HostBinding('class') @Input() public classList = 'block';

    public radiosGroup!: FormGroup;

    public onInfoLayerOpened(isOpen: boolean, item: RadioItem): void {
        this.infoLayerOpened.emit({ isOpen, fieldName: item.title, required: this.isRequired });
    }

    public ngOnInit(): void {
        this.radiosGroup = new FormGroup({
            [this.name]: this.formControl,
        });
    }
}
