import { NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { UiLabelComponent } from '@big-direkt/ui/label';
import { BaseControlValueAccessor, TypedSimpleChanges } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-ui-checkbox',
    templateUrl: './checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UiLabelComponent, NgClass, ReactiveFormsModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CheckboxComponent,
            multi: true,
        },
    ],
})
export class CheckboxComponent extends BaseControlValueAccessor<string> implements OnChanges, AfterViewInit {
    @Input({ required: true }) public id!: string;
    @Input({ required: true }) public value!: string;
    @Input() public title?: string;
    @Input() public titleHtml?: string;
    @Input() public isRequired = false;
    @Input() public isDisabled = false;
    @Input() public isValid = false;
    @Input() public isInvalid = false;

    @HostBinding('class') @Input() public classList = 'block';

    public ngOnChanges(changes: TypedSimpleChanges<CheckboxComponent>): void {
        if (changes.isDisabled) {
            this.setDisabledState(changes.isDisabled.currentValue);
        }
    }

    public ngAfterViewInit(): void {
        this.setDisabledState(this.isDisabled);
    }
}
