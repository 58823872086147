import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-label[label], big-ui-label[labelHtml]',
    templateUrl: './label.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslocoDirective],
    providers: [provideTranslationScope('uiLabel', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
})
export class LabelComponent {
    @Input() public label?: string;
    @Input() public labelHtml?: string;
    @Input() public for?: string;
    @Input() public isRequired = false;
    @Input() public isDisabled = false;

    @HostBinding('class') @Input() public classList = 'block';
}
