import { Component, OnInit } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-checkbox',
    templateUrl: './checkbox.component.html',
    providers: [provideTranslationScope('ftbCheckbox', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    standalone: false,
})
export class CheckboxComponent extends InputBaseComponent implements OnInit {
    public readonly errorOverrides = { required: 'ftbCheckbox.required' };

    public isSingleItem = true;

    public static register(): ComponentMap {
        return {
            checkbox: CheckboxComponent,
        };
    }

    public override init(): void {
        super.init();

        if (this.settings.attributes?.requiredError) {
            this.errorOverrides.required = this.settings.attributes.requiredError;
        }
    }

    public ngOnInit(): void {
        if (this.settings.parent?.type === 'big_webform_field_group') {
            this.classList = 'block mb-2';
        }
    }
}
