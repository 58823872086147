/* eslint-disable sonarjs/no-duplicate-string */
import { Directive, HostListener, Input, inject } from '@angular/core';
import { EventBusService, type TrackingFormsFieldChangeEvent } from '@big-direkt/event-bus';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { FormService } from '../../services/form/form.service';
import { PageNavigationService } from '../../services/page-navigation/page-navigation.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formFieldTracking]',
})
export class FieldTrackingDirective {
    private readonly millisecondsDivider = 1000;
    private readonly eventBus = inject(EventBusService);
    private readonly pageNavigationService = inject(PageNavigationService);
    private readonly formService = inject(FormService);

    @Input('formFieldTracking') public settings?: FormConfigElement;

    private focusStartTime?: number;

    @HostListener('focusin')
    public onFocus(): void {
        if (this.settings) {
            this.focusStartTime = new Date().getTime();
        }
    }

    @HostListener('drop', ['$event.target'])
    @HostListener('change', ['$event.target'])
    @HostListener('optionSelected', ['$event'])
    public onChange(target: HTMLInputElement): void {
        const formId: string | undefined = this.pageNavigationService.pages[0].settings.webform;

        if (this.settings && formId !== undefined) {
            let fieldName: string | undefined = this.settings.title;

            // If a checkbox, that was placed in a group will be checked, we'd like to track it's group title
            if (this.settings.type === 'checkbox' && this.settings.parent?.type === 'big_webform_field_group') {
                fieldName = this.settings.parent.title;
            }

            const control = this.formService.getFormControl(this.settings.arrayParents);

            const event: TrackingFormsFieldChangeEvent['data'] = {
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                required: control?.isRequired,
                triggerElement: target.tagName,
                fieldId: this.settings.id,
                fieldName,
                formId,
                duration: (new Date().getTime() - (this.focusStartTime ?? 0)) / this.millisecondsDivider,
            };

            this.eventBus.emit({
                key: 'forms_field_change_event',
                data: event,
            });

            if (this.formService.isPageValidationFailed) {
                this.eventBus.emit({
                    key: 'forms_field_correction_event',
                    data: event,
                });
            }
        }
    }
}
