<input
    class="block relative shrink-0 h-5 w-5 cursor-pointer appearance-none rounded-full border-2 border-gray-300 hover:border-gray-500 focus:border-gray-500 focus-visible:outline-none checked:!border-primary bg-white before:absolute before:block before:content[''] before:w-[10px] before:h-[10px] before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full checked:before:bg-primary"
    type="radio"
    [id]="id"
    [attr.name]="name"
    [name]="name"
    [formControl]="formControl"
    value="{{ item.returnValue }}"
    (ngModelChange)="onChange($event)"
    [ngClass]="{
        '!border-success': isValid,
        '!border-danger': isInvalid,
    }" />
