import { Component, DestroyRef, Input, inject, type OnChanges } from '@angular/core';
import { FormConfigElement, type PageHierarchyNode } from '@big-direkt/form/contracts';
import { type TypedSimpleChanges } from '@big-direkt/utils/shared';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { ComponentService } from '../../../services/component/component.service';
import { ErrorMessageService } from '../../../services/error-message/error-message.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-page',
    templateUrl: './page.component.html',
    standalone: false,
})
export class PageComponent extends StructureBaseComponent implements OnChanges {
    private readonly componentService = inject(ComponentService);
    public readonly errorMessageService = inject(ErrorMessageService);
    public readonly destroyRef = inject(DestroyRef);

    @Input() public declare settings: FormConfigElement;

    public override get isHidden(): boolean {
        if (!this.pageNavigationService.getCurrentPage()) {
            return true;
        }

        // eslint-disable-next-line max-len
        return !(
            this.pageNavigationService.getCurrentPage()?.ancestorsAndSelf.some((page: PageHierarchyNode): boolean => page.settings === this.settings) ?? false
        );
    }

    public static register(): ComponentMap {
        return {
            big_webform_page: PageComponent, // eslint-disable-line @typescript-eslint/naming-convention
            big_webform_repeated_page: PageComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public ngOnChanges(changes: TypedSimpleChanges<PageComponent>): void {
        if (changes.settings?.previousValue) {
            this.componentService.detachViewContainer(this.elementHost?.viewContainerRef);
        }
        if (changes.settings?.currentValue) {
            this.componentService.insertViewChildren(changes.settings.currentValue.children, this.elementHost?.viewContainerRef);
        }
    }
}
