import { Inject, Pipe, type PipeTransform } from '@angular/core';
import { PageVisibleService } from '@big-direkt/form/preview';
import { PageNavigationService } from '../../services/page-navigation/page-navigation.service';
import { type PageHierarchyNode } from '@big-direkt/form/contracts';

@Pipe({
    name: 'previewVisible',
    // eslint-disable-next-line @angular-eslint/no-pipe-impure
    pure: false,
    standalone: false,
})
export class PreviewVisiblePipe implements PipeTransform {
    private readonly pageVisibleService: PageVisibleService;

    public constructor(@Inject(PageNavigationService) pageNavigationService: PageNavigationService) {
        this.pageVisibleService = new PageVisibleService(pageNavigationService);
    }

    public transform(pages: PageHierarchyNode[], includeDescendants = false): PageHierarchyNode[] {
        return this.pageVisibleService.getVisiblePages(pages, includeDescendants);
    }
}
