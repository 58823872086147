import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { RadioItem } from '../models/radio-item.model';

@Component({
    selector: 'big-ui-radio',
    templateUrl: './radio.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, ReactiveFormsModule, FormsModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RadioComponent,
            multi: true,
        },
    ],
})
export class RadioComponent extends BaseControlValueAccessor<string> {
    @Input({ required: true }) public id!: string;
    @Input({ required: true }) public name!: string;
    @Input({ required: true }) public item!: RadioItem;
    @Input() public isRequired = false;
    @Input() public isDisabled = false;
    @Input() public isValid = false;
    @Input() public isInvalid = false;
    @Input() public selected?: string;
}
