import { ChangeDetectionStrategy, Component, HostBinding, Input, type OnChanges } from '@angular/core';
import { type ValidationErrors } from '@angular/forms';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { type TypedSimpleChanges } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { type MappedError } from '../models/mapped-error.model';
import { type MessageTemplate } from '../models/message-template.model';

const defaultTemplate: MessageTemplate = 'uiError.error.default';
const requiredTemplate: MessageTemplate = 'uiError.error.required';
const minTemplate: MessageTemplate<{ min: number }> = (errorDetails: { min: number }) => ({
    i18nKey: 'uiError.error.min',
    params: { min: errorDetails.min },
});
const maxTemplate: MessageTemplate<{ max: number }> = (errorDetails: { max: number }) => ({
    i18nKey: 'uiError.error.max',
    params: { max: errorDetails.max },
});

const minLengthTemplate: MessageTemplate<{ requiredLength: number }> = (errorDetails: { requiredLength: number }) => ({
    i18nKey: 'uiError.error.minLength',
    params: { min: errorDetails.requiredLength },
});

const maxLengthTemplate: MessageTemplate<{ requiredLength: number }> = (errorDetails: { requiredLength: number }) => ({
    i18nKey: 'uiError.error.maxLength',
    params: { max: errorDetails.requiredLength },
});

const patternTemplate: MessageTemplate = 'uiError.error.pattern';

export const messageTemplates = {
    default: defaultTemplate,
    required: requiredTemplate,
    pattern: patternTemplate,
    min: minTemplate,
    max: maxTemplate,
    minlength: minLengthTemplate,
    maxlength: maxLengthTemplate,
} as const;

@Component({
    selector: 'big-ui-error',
    imports: [TranslocoDirective],
    providers: [provideTranslationScope('uiError', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
    templateUrl: './error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnChanges {
    @Input() public errors: ValidationErrors | null | undefined = undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() public overrides?: Partial<Record<string, MessageTemplate<any>>>;
    @Input() public onlyRenderFirstError = false;
    @HostBinding('class') @Input() public classList = 'block';

    public mappedErrors: MappedError[] = [];

    public ngOnChanges(changes: TypedSimpleChanges<ErrorComponent>): void {
        if (changes.errors ?? changes.overrides) {
            this.mappedErrors = this.mapErrors(this.errors);
        }
    }

    private mapErrors(errors: ValidationErrors | null | undefined): MappedError[] {
        if (!errors) {
            return [];
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const templates: Partial<Record<string, MessageTemplate<any>>> = messageTemplates;

        return Object.keys(errors).map(key => {
            const template = this.overrides?.[key] ?? templates[key] ?? messageTemplates.default;

            const message = typeof template === 'function' ? template(errors[key]) : { i18nKey: template };

            return {
                type: key,
                message,
            };
        });
    }
}
