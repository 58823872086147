import { Component } from '@angular/core';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-textarea',
    templateUrl: './textarea.component.html',
    standalone: false,
})
export class TextareaComponent extends TextfieldBaseComponent {
    public static register(): ComponentMap {
        return {
            textarea: TextareaComponent,
        };
    }
}
