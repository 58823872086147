@if (settings.isSubmitPage && errorMessageService.messages.length > 0) {
    <big-ui-alert
        type="danger"
        [title]="'general.errors.generic' | stringReplacePipe"
        [classList]="'flex text-center border-2 rounded-md !p-4 mb-20 flex-col align-items-center'">
        @for (message of errorMessageService.messages; track $index) {
            <div [innerHTML]="message"></div>
        }
    </big-ui-alert>
}

<fieldset>
    @if (settings.titleDisplay !== 'invisible') {
        <legend class="float-left w-full pl-0 mt-4 h4 col-sm-12">
            {{ label }}
        </legend>
    }

    <ng-template appElementHost />
</fieldset>
