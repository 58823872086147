import { MeiliSearchError } from "./meilisearch-error.js";
export class MeiliSearchApiError extends MeiliSearchError {
  name = "MeiliSearchApiError";
  cause;
  response;
  constructor(response, responseBody) {
    super(responseBody?.message ?? `${response.status}: ${response.statusText}`);
    this.response = response;
    if (responseBody !== undefined) {
      this.cause = responseBody;
    }
  }
}