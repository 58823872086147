import { formatDate } from '@angular/common';
import { Component, inject, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AllowedDrupalType } from '@big-direkt/form/contracts';
import { UiDateRangeComponent } from '@big-direkt/ui/date-range';
import { type MessageTemplateResult } from '@big-direkt/ui/error';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { Nl2brPipe } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { FieldTrackingDirective } from '../../../directives/field-tracking/field-tracking.directive';
import { ComponentService } from '../../../services/component/component.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-date-range',
    templateUrl: './date-range.component.html',
    imports: [ReactiveFormsModule, UiDateRangeComponent, UiFormRowComponent, Nl2brPipe, TranslocoDirective, FieldTrackingDirective],
})
export class DateRangeComponent extends InputBaseComponent {
    private readonly locale = inject(LOCALE_ID);
    private readonly componentService = inject(ComponentService);

    public readonly errorOverrides = {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        required: 'ftbForm.validation.daterange.invalid',
        mask: 'ftbForm.validation.daterange.invalid',
        hasInvalidDate: 'ftbForm.validation.daterange.invalid',
        matDatepickerMin: (errorDetails: { min: string }): MessageTemplateResult => ({
            i18nKey: 'ftbForm.validation.daterange.min',
            params: { min: formatDate(errorDetails.min, 'dd.MM.yyyy', this.locale) },
        }),
        matDatepickerMax: (errorDetails: { max: string }): MessageTemplateResult => ({
            i18nKey: 'ftbForm.validation.daterange.max',
            params: { max: formatDate(errorDetails.max, 'dd.MM.yyyy', this.locale) },
        }),
        matEndDateInvalid: 'ftbForm.validation.daterange.invalid',
        matStartDateInvalid: 'ftbForm.validation.daterange.invalid',
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static override initChildControls(): void {}

    public override init(): void {
        super.init();
        this.componentService.registerForManualChildHandling(Object.keys(DateRangeComponent.register()) as AllowedDrupalType[]);
    }

    public static register(): ComponentMap {
        return {
            big_webform_date_range: DateRangeComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public updateErrors(errors: Record<string, unknown> | null): void {
        this.control?.setErrors(errors);
    }
}
