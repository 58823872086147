import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconBigLightHinweis, IconBigLightInfo, IconBigLightOkKreis, IconComponent, type BigIcon } from '@big-direkt/utils/icons';

export type AlertType = 'success' | 'warning' | 'info' | 'danger';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'big-ui-alert',
    templateUrl: './alert.component.html',
    imports: [IconComponent, NgClass],
})
export class AlertComponent {
    public readonly iconMap: Partial<Record<AlertType, BigIcon>> = {
        info: IconBigLightInfo,
        success: IconBigLightOkKreis,
        warning: IconBigLightHinweis,
        danger: IconBigLightHinweis,
    };

    @Input() public title: string | undefined;
    @Input({ required: true }) public type!: AlertType;
    @Input() public classList: string | undefined;

    @HostBinding('class') public class = 'block';
}
