import { KeyValuePipe, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    HostBinding,
    Input,
    QueryList,
    Renderer2,
    ViewChildren,
    inject,
    type AfterViewInit,
    type ElementRef,
    type OnDestroy,
    type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-one-time-token',
    templateUrl: './one-time-token.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, ReactiveFormsModule, TranslocoDirective, KeyValuePipe],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: OneTimeTokenComponent,
            multi: true,
        },
        provideTranslationScope('uiOneTimeToken', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`)),
    ],
})
export class OneTimeTokenComponent extends BaseControlValueAccessor<string> implements OnInit, AfterViewInit, OnDestroy {
    private readonly unlisteners: (() => void)[] = [];

    private readonly destroyRef = inject(DestroyRef);
    private readonly renderer2 = inject(Renderer2);

    @HostBinding('class') public classList = 'block';

    @Input({ required: true }) public numberFields!: number;
    @Input({ required: true }) public id!: string;
    @Input() public inputType = 'tel';
    @Input() public isTouched = false;
    @Input() public isRequired = false;
    @Input() public isValid = true;
    @Input() public showValidation = false;
    @Input() public isDisabled = false;
    @Input() public value = '';

    @ViewChildren('controls') public readonly controls?: QueryList<ElementRef<HTMLInputElement>>;

    public formGroup!: FormGroup;

    public ngOnInit(): void {
        const controls: Record<string, FormControl> = {};

        Array.from({ length: this.numberFields }, (_e, i) => {
            controls[`input_${i}`] = new FormControl({
                value: this.value !== '' ? this.value[i] : undefined,
                disabled: this.isDisabled,
            });

            return i;
        });

        this.formGroup = new FormGroup(controls);

        if (this.isDisabled) {
            this.formGroup.disable();
        }

        this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((values: Record<string, string>) => {
            this.propagateChange?.(this.combineSingleValues(values));
        });
    }

    public ngAfterViewInit(): void {
        if (!this.isDisabled) {
            this.controls?.forEach(control => {
                this.unlisteners.push(
                    this.renderer2.listen(control.nativeElement, 'paste', (event: ClipboardEvent) => {
                        this.bindPasteHandler(event, control);
                    }),
                );
            });
        }
    }

    public ngOnDestroy(): void {
        this.unlisteners.forEach(unlistener => {
            unlistener();
        });
    }

    public onKeyUpHandler(index: number, event: Event): void {
        if (!(event instanceof KeyboardEvent)) {
            return;
        }

        const eventKey = event.key.toLocaleLowerCase();

        // User jumps back
        if (eventKey === 'tab' || event.code === 'ShiftLeft') {
            return;
        }

        // User removes content from input
        if (eventKey === 'backspace') {
            this.controls?.get(index - 1)?.nativeElement.select();

            return;
        }

        if (!this.formGroup.get(`input_${index}`)?.value) {
            return;
        }

        this.controls?.get(index + 1)?.nativeElement.select();
    }

    private combineSingleValues(values: Record<string, string>): string {
        return Object.values(values)
            .map(value => value)
            .join('');
    }

    private bindPasteHandler(event: ClipboardEvent, control: ElementRef<HTMLInputElement>): void {
        const clipboardValue = event.clipboardData?.getData('text');

        if (!clipboardValue) {
            return;
        }

        clipboardValue
            .substring(0, this.numberFields)
            .split('')
            .forEach((value: string, index: number) => {
                this.formGroup.get(`input_${index}`)?.setValue(value);
            });

        control.nativeElement.blur();
    }
}
