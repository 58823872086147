/** Removes undefined entries from object */
function removeUndefinedFromObject(obj) {
  return Object.entries(obj).reduce((acc, curEntry) => {
    const [key, val] = curEntry;
    if (val !== undefined) acc[key] = val;
    return acc;
  }, {});
}
async function sleep(ms) {
  return await new Promise(resolve => setTimeout(resolve, ms));
}
function addProtocolIfNotPresent(host) {
  if (!(host.startsWith("https://") || host.startsWith("http://"))) {
    return `http://${host}`;
  }
  return host;
}
function addTrailingSlash(url) {
  if (!url.endsWith("/")) {
    url += "/";
  }
  return url;
}
export { sleep, removeUndefinedFromObject, addProtocolIfNotPresent, addTrailingSlash };