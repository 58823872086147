import { formatDate, NgClass } from '@angular/common';
import {
    afterRenderEffect,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    inject,
    input,
    LOCALE_ID,
    Output,
    ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule, MatDateRangePicker } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumKalender, IconComponent } from '@big-direkt/utils/icons';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { IMaskDirective } from 'angular-imask';
import IMask from 'imask/holder';
import 'imask/masked/pattern';
import { dateRangeStringToObject } from '../date-range-string-to-object';

const MAX_YEAR = 9999;
type DateRangeBoundaries = string | Date | undefined;

@Component({
    selector: 'big-ui-date-range',
    templateUrl: './date-range.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideNativeDateAdapter(),
        provideTranslationScope('uiDateRange', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateRangeComponent,
            multi: true,
        },
    ],
    imports: [FormsModule, IconComponent, IMaskDirective, MatFormFieldModule, MatDatepickerModule, ReactiveFormsModule, TranslocoDirective, NgClass],
})
export class DateRangeComponent extends BaseControlValueAccessor<string> {
    private readonly local = inject(LOCALE_ID);
    public readonly dateRangePickerControl = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });
    public readonly formControlChanges = toSignal(this.formControl.valueChanges);
    public readonly iconCalendar = IconBigMediumKalender;
    public readonly id = input.required<string>();
    public readonly isInvalid = input.required<boolean>();
    public readonly isRequired = input.required<boolean>();
    public readonly isValid = input.required<boolean>();
    public readonly maxDate = input<Date | undefined, DateRangeBoundaries>(undefined, {
        transform: (value: DateRangeBoundaries): Date | undefined => (value ? new Date(value) : undefined),
    });
    public readonly minDate = input<Date, DateRangeBoundaries>(this.defaultMinDate(), {
        transform: (value: DateRangeBoundaries): Date => (value ? new Date(value) : this.defaultMinDate()),
    });

    @Output() public readonly errors = new EventEmitter<Record<string, unknown> | null>();

    @HostBinding('class') public classList = 'block';

    @ViewChild('dateRangePicker') public dateRangePicker!: MatDateRangePicker<Date>;

    public dateRangeMask = IMask.createMask({
        mask: 'd.`m.`y`{ - }d.`m.`y',
        blocks: {
            d: { mask: IMask.MaskedRange, from: 1, to: 31, maxLength: 2 },
            m: { mask: IMask.MaskedRange, from: 1, to: 12, maxLength: 2 },
            y: { mask: IMask.MaskedRange, from: 0, to: MAX_YEAR },
        },
    });

    public constructor() {
        super();

        afterRenderEffect(() => {
            // eslint-disable-next-line no-null/no-null
            const value = this.formControlChanges() ?? null;

            this.dateRangePickerControl.setValue(dateRangeStringToObject(value));
            this.dateRangePickerControl.updateValueAndValidity();
            this.formControl.updateValueAndValidity();
            this.onChange(value);
            this.emitErrors();
        });
    }

    public datePickerOpen(event: Event): void {
        event.preventDefault();

        this.dateRangePicker.open();
    }

    public datePickerClose(): void {
        const dateRangeValue = this.dateRangePickerControl.value;
        // eslint-disable-next-line no-null/no-null
        const startDate = dateRangeValue.start !== null ? formatDate(new Date(dateRangeValue.start), 'dd.MM.YYYY', this.local) : '';
        // eslint-disable-next-line no-null/no-null
        const endDate = dateRangeValue.end !== null ? formatDate(new Date(dateRangeValue.end), 'dd.MM.YYYY', this.local) : '';

        if (!startDate && !endDate) {
            // eslint-disable-next-line no-null/no-null
            this.formControl.setValue(null);

            return;
        }

        this.formControl.setValue(`${startDate} - ${endDate}`);
    }

    private emitErrors(): void {
        const errors = {
            ...this.dateRangePickerControl.get('start')?.errors,
            ...this.dateRangePickerControl.get('end')?.errors,
            ...this.formControl.errors,
            ...(this.isRequired() && !this.formControl.value && { required: true }),
            ...(this.formControl.value && !this.dateRangeMask.isFilled && { mask: 'error' }),
        };

        // eslint-disable-next-line no-null/no-null
        this.errors.emit(Object.keys(errors).length ? errors : null);
    }

    private defaultMinDate(): Date {
        const currentDate = new Date();

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());
    }
}
