/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import IMask from 'imask/holder';
import MaskedPattern from 'imask/masked/pattern';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { Iban } from '../../../utilities/iban/iban';
import { LibPhoneNumber } from '../../../utilities/lib-phone-number/lib-phone-number';

@Component({
    selector: 'big-form-text',
    templateUrl: './textfield.component.html',
    providers: [provideTranslationScope('ftbTextfield', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    standalone: false,
})
export class TextfieldComponent extends TextfieldBaseComponent {
    private readonly textFieldMask = IMask.createMask({
        mask: 'aa00 **** **** **** **** **** **** **** **',
    });

    public errorOverrides = {
        default: 'ftbTextfield.required',
        required: 'ftbTextfield.required',
        customPatternMessage: 'ftbTextfield.customPattern',
        customPattern: 'ftbTextfield.customPattern',
        email: 'ftbTextfield.email',
        iban: 'ftbTextfield.iban',
        phoneNumber: 'ftbTextfield.phone',
    };

    public get mask(): MaskedPattern | undefined {
        if (this.settings.type === 'big_webform_iban') {
            return this.textFieldMask;
        }

        return undefined;
    }

    public override init(): void {
        super.init();

        if (this.settings.attributes?.patternError) {
            this.errorOverrides.customPatternMessage = this.settings.attributes.patternError;
        }

        if (this.settings.attributes?.requiredError) {
            this.errorOverrides.required = this.settings.attributes.requiredError;
        }
    }

    public static register(): ComponentMap {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            big_webform_bic: TextfieldComponent,
            big_webform_iban: {
                component: TextfieldComponent,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                validators: [Iban.validateFormControl],
            },
            big_webform_telephone: {
                component: TextfieldComponent,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                validators: [LibPhoneNumber.validateFormControl],
            },
            email: {
                component: TextfieldComponent,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                validators: [Validators.email],
            },
            tel: {
                component: TextfieldComponent,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                validators: [LibPhoneNumber.validateFormControl],
            },
            textfield: TextfieldComponent,
            url: TextfieldComponent,
        };
    }
}
