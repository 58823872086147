import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormConfigElement, type AllowedDrupalType } from '@big-direkt/form/contracts';
import { SelectOption } from '@big-direkt/ui/select';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { SelectBaseComponent } from '../../../base-components/select-base/select-base.component';
import { type TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { BigAnyFormControl } from '../../../form-control/big-any-form-control';
import { type AddressComponentConfig } from '../../../interfaces/address-component-config';
import { ComponentService } from '../../../services/component/component.service';
import { FormService } from '../../../services/form/form.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { type TextfieldComponent } from '../textfield/textfield.component';

const DEFAULT_GERMAN_ERROR_OVERRIDES = {
    minlength: 'ftbAddress.germanPostalCodeValidation',
    maxlength: 'ftbAddress.germanPostalCodeValidation',
};

const ADDRESS_KEYS = new Map<string, string>([
    ['[address]', 'address'],
    ['[address_2]', 'address2'],
    ['[post_box]', 'postBox'],
    ['[address_addition]', 'addressAddition'],
    ['[city]', 'city'],
    ['[state_province]', 'stateProvince'],
    ['[postal_code]', 'postalCode'],
    ['[country]', 'country'],
]);
@Component({
    selector: 'big-form-address',
    templateUrl: './address.component.html',
    providers: [provideTranslationScope('ftbAddress', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    standalone: false,
})
export class AddressComponent extends NestedValuesBaseComponent implements OnInit {
    private readonly componentService = inject(ComponentService);
    private readonly destroyRef = inject(DestroyRef);

    private readonly requiredLengthGermanPostalCode = 5;
    private readonly maxLengthInternationalPostalCode = 10;

    private readonly germanPatternValidator = Validators.pattern('^(?!00)[0-9]+');
    private readonly minLengthGermanValidator = Validators.minLength(this.requiredLengthGermanPostalCode);
    private readonly maxLengthGermanValidator = Validators.maxLength(this.requiredLengthGermanPostalCode);

    private readonly internationalPatternValidator = Validators.pattern('[a-zA-z0-9 -]+');
    private readonly maxLengthInternationalValidator = Validators.maxLength(this.maxLengthInternationalPostalCode);
    public readonly selectErrorOverrides = { required: 'ftbForm.validation.mustBeAValueFromTheList' };

    public errorOverrides: Record<string, string> | undefined = { ...DEFAULT_GERMAN_ERROR_OVERRIDES };

    public address?: TextfieldBaseComponent;
    public address2?: TextfieldBaseComponent;
    public postBox?: TextfieldBaseComponent;
    public addressAddition?: TextfieldBaseComponent;
    public postalCode?: TextfieldBaseComponent;
    public city?: TextfieldBaseComponent;
    public stateProvince?: SelectBaseComponent;
    public country?: SelectBaseComponent;

    public countryOptionsWithoutEmptyOption = signal<SelectOption<string>[]>([]);

    public static register(): ComponentMap {
        return {
            webform_address: AddressComponent, // eslint-disable-line @typescript-eslint/naming-convention
            big_webform_address: AddressComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public static override initChildControls(settings: FormConfigElement, formService: FormService): void {
        AddressComponent.executeForChildren(settings, child => {
            formService.createControls(child);
        });
    }

    public override init(): void {
        super.init();
        this.componentService.registerForManualChildHandling(Object.keys(AddressComponent.register()) as AllowedDrupalType[]);
    }

    public ngOnInit(): void {
        AddressComponent.executeForChildren(this.settings, (settings, addressItemKey) => {
            const { instance } = this.componentService.createComponent<SelectBaseComponent | TextfieldComponent>(settings);

            if (instance instanceof SelectBaseComponent) {
                this[addressItemKey] = instance;
            } else {
                (this[addressItemKey] as TextfieldBaseComponent) = instance;
            }
        });

        if (!this.country?.control?.value) {
            this.handlePostalCodeValidators(true, this.postalCode?.control);
        }

        this.country?.control?.valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                startWith(this.country.control.value),
                filter(country => !!country),
                map(country => country === 'Deutschland' || country === 'DE'),
                distinctUntilChanged(),
                tap(isGermany => {
                    this.handlePostalCodeValidators(isGermany, this.postalCode?.control);
                }),
            )
            .subscribe();
    }

    private static executeForChildren(
        settings: FormConfigElement,
        handler: (child: FormConfigElement, addressItemKey: keyof AddressComponentConfig) => void,
    ): void {
        settings.children.forEach((child: FormConfigElement): void => {
            // eslint-disable-next-line sonarjs/slow-regex
            const match: RegExpMatchArray | undefined = /\[.+\]/.exec(child.name) ?? undefined;

            if (!match) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const key: keyof AddressComponentConfig | undefined = match ? (ADDRESS_KEYS.get(match[0]) as keyof AddressComponentConfig) : undefined;

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!key) {
                return;
            }

            handler(child, key);
        });
    }

    private handlePostalCodeValidators(isGermany: boolean, control: BigAnyFormControl | undefined): void {
        const germanValidators = [this.minLengthGermanValidator, this.maxLengthGermanValidator, this.germanPatternValidator];
        const internationalValidators = [this.maxLengthInternationalValidator, this.internationalPatternValidator];

        if (!control) {
            return;
        }

        if (isGermany) {
            internationalValidators.forEach(internationalValidator => {
                this.removeValidatorsFromControl(control, internationalValidator);
            });
            control.rawValidators = [...control.rawValidators, ...germanValidators];
        } else {
            germanValidators.forEach(germanValidator => {
                this.removeValidatorsFromControl(control, germanValidator);
            });
            control.rawValidators = [...control.rawValidators, ...internationalValidators];
        }

        this.errorOverrides = isGermany ? { ...DEFAULT_GERMAN_ERROR_OVERRIDES } : undefined;

        control.setValidators([...control.rawValidators]);
        control.updateValueAndValidity();
    }

    private removeValidatorsFromControl(control: BigAnyFormControl, validator: ValidatorFn): void {
        const index = control.rawValidators.findIndex(rawValidator => rawValidator === validator);
        if (index !== -1) {
            control.rawValidators.splice(index, 1);
            // sometimes we have multiple validators (maxlength) and it removes the wrong one
            // with this code we can ensure to always register the correct validator
            this.removeValidatorsFromControl(control, validator);
        }
    }
}
