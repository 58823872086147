import { Component } from '@angular/core';
import { ComponentMap } from '../../../utilities/component-map/component-map';
import { SelectComponent } from './../select/select.component';

@Component({
    selector: 'big-form-quantity-select',
    templateUrl: './quantity-select.component.html',
    standalone: false,
})
export class QuantitySelectComponent extends SelectComponent {
    public override classList = 'block py-6 [&:not(:last-of-type)]:border-b-2 border-gray-300';

    public static override register(): ComponentMap {
        return {
            big_webform_quantity_select: QuantitySelectComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
