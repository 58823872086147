/* eslint-disable sonarjs/no-duplicate-string, @typescript-eslint/no-non-null-assertion */
import { Directive, HostListener, Input, effect, inject, input } from '@angular/core';
import { EventBusService, TrackingFormsFormSearchEvent } from '@big-direkt/event-bus';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { FormSearchTrackingInfo } from '../../interfaces/form-search-tracking-info';
import { FormService } from '../../services/form/form.service';
import { PageNavigationService } from '../../services/page-navigation/page-navigation.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formSearchTracking]',
})
export class FormSearchTrackingDirective {
    private readonly millisecondsDivider = 1000;
    private readonly eventBus = inject(EventBusService);
    private readonly pageNavigationService = inject(PageNavigationService);
    private readonly formService = inject(FormService);

    @Input('formSearchTracking') public settings?: FormConfigElement;
    public searchTrackingInfo = input.required<FormSearchTrackingInfo>({});

    private focusStartTime?: number;
    private currentTarget?: HTMLInputElement;

    public constructor() {
        effect(() => {
            const formId: string | undefined = this.pageNavigationService.pages[0].settings.webform;

            if (
                !this.searchTrackingInfo().entryType ||
                !this.searchTrackingInfo().q ||
                !this.searchTrackingInfo().src ||
                !this.searchTrackingInfo().isNewSearchSubmit ||
                !this.settings ||
                formId === undefined
            ) {
                return;
            }

            const control = this.formService.getFormControl(this.settings.arrayParents);

            const event: TrackingFormsFormSearchEvent['data'] = {
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                required: control?.isRequired,
                triggerElement: this.currentTarget?.tagName ?? 'INPUT',
                fieldId: this.settings.id,
                fieldName: this.settings.title,
                formId,
                duration: (new Date().getTime() - (this.focusStartTime ?? 0)) / this.millisecondsDivider,
                entryType: this.searchTrackingInfo().entryType!,
                q: this.searchTrackingInfo().q!,
                cat: 'search-form',
                res: this.searchTrackingInfo().res ?? 0,
                src: this.searchTrackingInfo().src!,
            };

            this.eventBus.emit({
                key: 'forms_form_search_event',
                data: event,
            });
        });
    }

    @HostListener('focusin')
    public onFocus(): void {
        if (this.settings) {
            this.focusStartTime = new Date().getTime();
        }
    }

    @HostListener('change', ['$event.target'])
    @HostListener('optionSelected', ['$event'])
    public onChange(target: HTMLInputElement): void {
        this.currentTarget = target;
    }
}
