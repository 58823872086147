import { Component } from '@angular/core';
import { type BigUntypedFormGroup } from '../../form-control/big-form-group';
import { InputBaseComponent } from '../input-base/input-base.component';

@Component({
    selector: 'big-form-nested-values-base',
    template: '',
    standalone: false,
})
export class NestedValuesBaseComponent extends InputBaseComponent {
    public declare control?: BigUntypedFormGroup;
}
