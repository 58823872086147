import { A11yModule } from '@angular/cdk/a11y';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, SecurityContext, WritableSignal, inject, signal, type OnInit } from '@angular/core';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiInfoButtonComponent } from '@big-direkt/ui/info-button';
import { WindowService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumAntragDownload, IconBigMediumOkKreis, IconBigMediumSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { SickNotesModel } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgPopoverRef } from 'ng-overlay-container';
import { SickNoteDetailsOverlayData } from '../../models/sick-note-details-overlay-data.model';

@Component({
    selector: 'big-ui-sick-note-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sick-note-details.component.html',
    imports: [A11yModule, DatePipe, IconComponent, NgTemplateOutlet, TranslocoDirective, UiButtonComponent, UiInfoButtonComponent],
    providers: [
        provideTranslationScope('uiModalSickNoteDetails', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`)),
    ],
})
export class ModalSickNoteDetailsComponent implements OnInit {
    private readonly windowService = inject(WindowService);
    public readonly popoverRef = inject<NgPopoverRef<SickNoteDetailsOverlayData>>(NgPopoverRef);

    public readonly iconClose = IconBigMediumSchliessen;
    public readonly iconCheckMark = IconBigMediumOkKreis;
    public readonly iconDownload = IconBigMediumAntragDownload;

    public readonly securityContext = SecurityContext.HTML;

    @HostBinding('class') public classList = 'block';

    public sickNote!: SickNotesModel;
    public openInfoTextIds: WritableSignal<string[]> = signal([]);

    public ngOnInit(): void {
        const { sickNote } = this.popoverRef.data;

        this.sickNote = sickNote;
    }

    public onInfoLayerOpen(isOpen: boolean, id: string): void {
        let ids = this.openInfoTextIds();

        if (isOpen && !ids.includes(id)) {
            ids.push(id);
        } else if (!isOpen) {
            ids = ids.filter(sickNoteId => sickNoteId !== id);
        }

        this.openInfoTextIds.set(ids);
    }

    public printSickNote(event: MouseEvent): void {
        event.preventDefault();

        this.windowService.nativeWindow()?.print();
    }
}
