import { Inject, Pipe, type PipeTransform } from '@angular/core';
import { type PlaceholderData } from '../../interfaces/string-replacement.data';
import { StringReplaceService } from '../../services/string-replace/string-replace.service';

@Pipe({
    name: 'stringReplacePipe',
})
export class StringReplacePipe implements PipeTransform {
    public constructor(@Inject(StringReplaceService) private readonly stringReplaceService: StringReplaceService) {}

    public transform(key: string, placeholders?: PlaceholderData): string {
        return this.stringReplaceService.get(key, placeholders);
    }
}
