import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
    selector: 'big-form-input-base',
    template: '<ng-container appElementHost></ng-container>',
    standalone: false,
})
export class InputBaseComponent extends BaseComponent {
    public get isValid(): boolean {
        return this.formService.isPageValidationFailed && !!this.control && this.control.touched && this.control.valid;
    }

    public get isInvalid(): boolean {
        return this.formService.isPageValidationFailed && (!this.control || (this.control.touched && !this.control.valid));
    }

    public get isPageValidationFailed(): boolean {
        return this.formService.isPageValidationFailed;
    }
}
