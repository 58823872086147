<div class="relative flex w-full">
    <mat-form-field class="absolute bottom-0 right-0 h-full ml-auto z-1">
        <mat-datepicker
            #datePicker
            (closed)="datePickerClose()" />
        <input
            matInput
            [formControl]="datePickerControl"
            [min]="minDate()"
            [max]="maxDate()"
            [matDatepicker]="datePicker" />
    </mat-form-field>

    <input
        class="z-0 w-full form-control"
        type="text"
        placeholder="TT.MM.JJJJ"
        [imask]="dateMask"
        [attr.id]="id()"
        [ngClass]="{
            'is-valid': isValid() && showValidation(),
            'is-invalid': isInvalid() && showValidation(),
        }"
        (blur)="hasFocus.set(false)"
        (focus)="hasFocus.set(true)"
        [required]="isRequired()"
        [formControl]="formControl" />

    <button
        *transloco="let t; prefix: 'uiDate'"
        class="absolute top-0 right-0 flex p-4 z-2 peer-focus:border-gray-200"
        (click)="openDatepicker($event)"
        [attr.aria-label]="t('buttonLabel')">
        <big-icon
            [icon]="iconCalendar"
            [size]="'w-6'"
            class="fill-primary" />
    </button>
</div>
